import ReactPlayer from 'react-player'
import { useSelector } from 'react-redux'

import '../../styles/pages.scss'

const WelcomeScreenDisplay = () => {
  const { device } = useSelector(state => state.resource)

  const adInfoType = device.options?.adInfo?.adType ?? false
  const adUrlVideoFormatted = device.options?.adInfo?.adUrl ?? ''
  const adUrlImageFormatted =
    device.options?.adInfo?.photoAdUrls &&
    Array.isArray(device.options.adInfo?.photoAdUrls)
      ? device.options.adInfo.photoAdUrls[0]
      : ''

  return (
    <div className="vector">
      {adInfoType === 'Video' && adUrlVideoFormatted !== '' && (
        <ReactPlayer
          url={adUrlVideoFormatted}
          playing={true}
          width="95vw"
          height="800px"
          loop={true}
          volume={0}
          style={{ pointerEvents: 'none' }} // used to disable payers buttons
        />
      )}

      {adInfoType === 'Photos' && adUrlImageFormatted !== '' && (
        <img
          style={{
            width: '95vw',
            height: '800px'
          }}
          src={adUrlImageFormatted}
        />
      )}
    </div>
  )
}

export default WelcomeScreenDisplay
